<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Order Reference',
          value: 'id',
        },
        {
          header: 'Customer',
          value: 'customer_name',
          sort: true
        },
        {
          header: 'Payment Status',
          value: 'billing_status',
          sort: true
        },
        {
          header: 'Fulfillment Status',
          value: 'fulfillment_status',
          sort: true
        },
        {
          header: 'Created At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Updated At',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      :filter-keys="filterKeys"
      :request-params="{
        billing_status_not: [
          'STATUS_AWAITING_PAYMENT'
        ]
      }"
      path="orders"
    >
      <template v-slot:item.billing_status="row">
        <span class="rounded-full bg-primary text-white px-2 py-1">
          {{ row.billing_status_label }}
        </span>
      </template>
      <template v-slot:item.fulfillment_status="row">
        <span class="rounded-full bg-primary text-white px-2 py-1">
          {{ row.fulfillment_status_label }}
        </span>
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-button
          primary
          sm
          @click.native="openOrder(row.id)"
        >
          View
        </vue-button>
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Orders',

  data () {
    return {
      billingStatusKeys: [
        { key: 'STATUS_AWAITING_PAYMENT', label: 'Awaiting Payment' },
        { key: 'STATUS_PAID', label: 'Paid' }
      ],
      fulfillmentStatusKeys: [
        { key: 'STATUS_PENDING', label: 'Pending' },
        { key: 'STATUS_FULFILLED', label: 'Fulfilled' }
      ]
    }
  },

  computed: {
    filterKeys () {
      return [
        { key: 'id', label: 'Order Reference', type: 'String' },
        { key: 'customer_name', label: 'Customer Name', type: 'String' },
        // { key: 'billing_status', label: 'Payment Status', type: 'Select', values: this.billingStatusKeys },
        { key: 'fulfillment_status', label: 'Fulfillment Status', type: 'Select', values: this.fulfillmentStatusKeys }
      ]
    }
  },

  methods: {
    openOrder (id) {
      this.$router.push({ name: 'orders.single', params: { order: id } })
    }
  }
}
</script>
